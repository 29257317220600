var Handlebars = require ('handlebars');
this["ilr"] = this["ilr"] || {};
this["ilr"]["templates"] = this["ilr"]["templates"] || {};
this["ilr"]["templates"]["catchup_content_large"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + ". "
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":55}}}) : helper)))
    + "</h3>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":25}}}) : helper)))
    + "</h3>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"date-time\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":131}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":134}}})) != null ? stack1 : "")
    + "\n                "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":56}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":20,"column":99},"end":{"line":20,"column":138}}}))
    + "\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Säsong "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Avsnitt "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"date-time\">"
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":23,"column":35},"end":{"line":23,"column":75}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":23,"column":118},"end":{"line":23,"column":157}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":16}}}) : helper)))
    + "\">\n    <div class=\"thumbnail small-12 medium-2 large-2 columns\">\n        <div class=\"play-button large\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":67}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":114},"end":{"line":3,"column":123}}}) : helper)))
    + "\" data-image=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":3,"column":137},"end":{"line":3,"column":146}}}) : helper)))
    + "\" data-artist=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":3,"column":161},"end":{"line":3,"column":178}}}) : helper)))
    + "\" data-catchup=\"true\" style=\"background-color: #"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? lookupProperty(stack1,"app_playerbar_color") : stack1), depth0))
    + ";\" data-content-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":3,"column":278},"end":{"line":3,"column":285}}}) : helper)))
    + "\" data-program-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":3,"column":305},"end":{"line":3,"column":320}}}) : helper)))
    + "\">\n            <div class=\"play-icon\"></div>\n        </div>\n        <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),400,400,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":62}}}))
    + "\" width=\"400\" height=\"400\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":6,"column":94},"end":{"line":6,"column":111}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":114},"end":{"line":6,"column":123}}}) : helper)))
    + "\">\n    </div>\n</a>\n<div class=\"title-description small-12 medium-10 large-10 columns\">\n    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":10,"column":13},"end":{"line":10,"column":20}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </a>\n    <p class=\"excerpt\">"
    + ((stack1 = (lookupProperty(helpers,"linkify")||(depth0 && lookupProperty(depth0,"linkify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"linkify","hash":{},"data":data,"loc":{"start":{"line":26,"column":23},"end":{"line":26,"column":48}}})) != null ? stack1 : "")
    + "</p>\n</div>";
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["catchup_content_small"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"small-12 medium-6 large-6 columns\">\n    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":20}}}) : helper)))
    + "\">\n        <div class=\"thumbnail small-4 medium-4 large-2 columns\">\n            <div class=\"play-button small\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":71}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":118},"end":{"line":5,"column":127}}}) : helper)))
    + "\" data-artist=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":5,"column":142},"end":{"line":5,"column":159}}}) : helper)))
    + "\" data-image=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":5,"column":173},"end":{"line":5,"column":182}}}) : helper)))
    + "\" data-catchup=\"true\" data-content-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":5,"column":222},"end":{"line":5,"column":229}}}) : helper)))
    + "\" data-program-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":5,"column":249},"end":{"line":5,"column":264}}}) : helper)))
    + "\" style=\"background-color: #"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? lookupProperty(stack1,"app_playerbar_color") : stack1), depth0))
    + ";\">\n                <div class=\"play-icon\"></div>\n            </div>\n            <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),200,200,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":66}}}))
    + "\" width=\"200\" height=\"200\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":8,"column":98},"end":{"line":8,"column":115}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":118},"end":{"line":8,"column":127}}}) : helper)))
    + "\">\n        </div>\n        <div class=\"small-8 medium-8 large-10 columns\">\n            <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":85}}})) != null ? stack1 : "")
    + alias4((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),40,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":11,"column":85},"end":{"line":11,"column":106}}}))
    + "</h5>\n            <p class=\"smalltext\">"
    + alias4((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),100,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":61}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </a>\n</li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + ". ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"date-time\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":138}}})) != null ? stack1 : "")
    + "\n                    "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":60}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":17,"column":103},"end":{"line":17,"column":142}}}))
    + "\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Säsong "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Avsnitt "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"date-time\">"
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":79}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":20,"column":122},"end":{"line":20,"column":161}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":25,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["catchup_details"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "avsnitt";
},"3":function(container,depth0,helpers,partials,data) {
    return "klipp";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section-cont dark\">\n    <h5>Kategorier</h5>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tags\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":35}}}) : helper)))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"section-cont dark\">\n    <h5>Prenumerera / Öppna podden i din favoritapp</h5>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobileOS") || (depth0 != null ? lookupProperty(depth0,"isMobileOS") : depth0)) != null ? helper : alias2),(options={"name":"isMobileOS","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":57,"column":19}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobileOS")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobileOS") || (depth0 != null ? lookupProperty(depth0,"isMobileOS") : depth0)) != null ? helper : alias2),(options={"name":"isMobileOS","hash":{},"fn":container.noop,"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":60,"column":19}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobileOS")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"btns w-clearfix\">\n            <a onclick=\"deeplink.openAppLink('podcasts/"
    + alias3(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":19,"column":55},"end":{"line":19,"column":63}}}) : helper)))
    + "');\" class=\"btn-subscribe inline ilikeradio-blue\">\n                <img src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"images/icon-vrv.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":60}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                <div class=\"btn-text\">Viaplay Radio</div>\n            </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"spotify_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"google_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"apple_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "            <a href=\"#\" data-link=\""
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"feed_url") : stack1), depth0))
    + "\" class=\"btn-subscribe inline dark copy-link\" onclick=\"return false;\">\n                <img src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"images/icon-rss.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":46,"column":26},"end":{"line":46,"column":60}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                <div class=\"btn-text\">Kopiera RSS</div>\n                <div class=\"copied-text\" style=\"transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 0;\">Länken är kopierad</div>\n            </a>\n"
    + ((stack1 = (lookupProperty(helpers,"hasExpandableSubscribeOptions")||(depth0 && lookupProperty(depth0,"hasExpandableSubscribeOptions"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0),{"name":"hasExpandableSubscribeOptions","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":55,"column":46}}})) != null ? stack1 : "")
    + "        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"spotify_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline dark\">\n                    <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-spotify.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":30},"end":{"line":25,"column":68}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                    <div class=\"btn-text\">Spotify</div>\n                </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isAndroid") || (depth0 != null ? lookupProperty(depth0,"isAndroid") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isAndroid","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":35,"column":26}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isAndroid")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"google_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline dark\">\n                    <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-google.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":32,"column":30},"end":{"line":32,"column":67}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                    <div class=\"btn-text\">Google Podcasts</div>\n                </a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isIOS") || (depth0 != null ? lookupProperty(depth0,"isIOS") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isIOS","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":43,"column":22}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isIOS")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"apple_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline dark\">\n                    <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-apple.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":66}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                    <div class=\"btn-text\">Apple Podcasts</div>\n                </a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"show-more-btns-container\">\n                    <div class=\"more show\" onclick=\"jQuery('.more.less').show(); jQuery('.btn-subscribe').show(); jQuery('.more.show').hide(); return false;\">Visa fler..</div>\n                    <div style=\"display:none\" class=\"more less\" onclick=\"jQuery('.more.show').show(); jQuery('.more.less').hide(); jQuery('.btn-subscribe:gt(2)').hide(); return false;\">Visa färre...</div>\n                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "        <p class=\"smalltext light\">Denna podd kan du lyssna på i alla populära poddappar inklusive Viaplay&nbsp;Radio. Besök denna sidan i din mobil så hjälper vi dig att hitta rätt.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"podcast-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":35}}}) : helper)))
    + "</h2>\n<div class=\"tags\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"audio_amount") || (depth0 != null ? lookupProperty(depth0,"audio_amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"audio_amount","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":34}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":81}}})) != null ? stack1 : "")
    + "</div>\n<p class=\"podcast-description excerpt\">"
    + ((stack1 = (lookupProperty(helpers,"linkify")||(depth0 && lookupProperty(depth0,"linkify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"linkify","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":64}}})) != null ? stack1 : "")
    + "</p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":62,"column":7}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["catchup_home"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "er";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"section-cont\">\n                <h5 class=\"light\">Kategorier</h5>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tags\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":47}}}) : helper)))
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"section-cont\">\n                <h5 class=\"light\">Prenumerera / Öppna podden i din favoritapp</h5>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobileOS") || (depth0 != null ? lookupProperty(depth0,"isMobileOS") : depth0)) != null ? helper : alias2),(options={"name":"isMobileOS","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":75,"column":31}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobileOS")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobileOS") || (depth0 != null ? lookupProperty(depth0,"isMobileOS") : depth0)) != null ? helper : alias2),(options={"name":"isMobileOS","hash":{},"fn":container.noop,"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":76,"column":16},"end":{"line":78,"column":31}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobileOS")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"btns w-clearfix\">\n                        <a onclick=\"deeplink.openAppLink('podcasts/"
    + alias3(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":37,"column":67},"end":{"line":37,"column":75}}}) : helper)))
    + "');\" class=\"btn-subscribe inline ilikeradio-blue\">\n                            <img src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"images/icon-vr.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":38,"column":38},"end":{"line":38,"column":71}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                            <div class=\"btn-text\">Viaplay Radio</div>\n                        </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"spotify_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"google_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":24},"end":{"line":54,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"apple_podcasts_url") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":62,"column":31}}})) != null ? stack1 : "")
    + "                        <a href=\"#\" data-link=\""
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"feed_url") : stack1), depth0))
    + "\" class=\"btn-subscribe inline copy-link\" onclick=\"return false;\">\n                            <img src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"images/icon-rss.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":64,"column":38},"end":{"line":64,"column":72}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                            <div class=\"btn-text\">Kopiera RSS</div>\n                            <div class=\"copied-text\" style=\"transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 0;\">Länken är kopierad</div>\n                        </a>\n"
    + ((stack1 = (lookupProperty(helpers,"hasExpandableSubscribeOptions")||(depth0 && lookupProperty(depth0,"hasExpandableSubscribeOptions"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0),{"name":"hasExpandableSubscribeOptions","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":73,"column":58}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"spotify_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline\">\n                                <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-spotify.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":43,"column":42},"end":{"line":43,"column":80}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                                <div class=\"btn-text\">Spotify</div>\n                            </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isAndroid") || (depth0 != null ? lookupProperty(depth0,"isAndroid") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isAndroid","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":53,"column":38}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isAndroid")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"google_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline\">\n                                <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-google.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":50,"column":42},"end":{"line":50,"column":79}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                                <div class=\"btn-text\">Google Podcasts</div>\n                            </a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isIOS") || (depth0 != null ? lookupProperty(depth0,"isIOS") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isIOS","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":61,"column":34}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isIOS")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"apple_podcasts_url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn-subscribe inline\">\n                                <img src=\""
    + alias1((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"images/icon-apple.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":58,"column":42},"end":{"line":58,"column":78}}}))
    + "\" alt=\"\" class=\"icon-sub\">\n                                <div class=\"btn-text\">Apple Podcasts</div>\n                            </a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"show-more-btns-container\">\n                                <div class=\"more show\" onclick=\"jQuery('.more.less').show(); jQuery('.btn-subscribe').show(); jQuery('.more.show').hide(); return false;\">Visa fler..</div>\n                                <div style=\"display:none\" class=\"more less\" onclick=\"jQuery('.more.show').show(); jQuery('.more.less').hide(); jQuery('.btn-subscribe:gt(2)').hide(); return false;\">Visa färre...</div>\n                            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                    <p class=\"smalltext light\">Denna podd kan du lyssna på i alla populära poddappar inklusive Viaplay&nbsp;Radio. Besök denna sidan i din mobil så hjälper vi dig att hitta rätt.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"catchup-home-container-inner\">\n    <div class=\"row\">\n        <div class=\"small-12 medium-4 large-4 columns\">\n            <div class=\"thumbnail\">\n                <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),350,350,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":70}}}))
    + "\" width=\"350\" height=\"350\" />\n            </div>\n        </div>\n        <div class=\"small-12 medium-8 large-8 columns\">\n            <h1 class=\"light\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":39}}}) : helper)))
    + "</h1>\n            <div class=\"date-time\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"seasons") || (depth0 != null ? lookupProperty(depth0,"seasons") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"seasons","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":46}}}) : helper)))
    + " säsong"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"seasons") : depth0),">",1,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":53},"end":{"line":10,"column":93}}})) != null ? stack1 : "")
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3(((helper = (helper = lookupProperty(helpers,"audio_amount") || (depth0 != null ? lookupProperty(depth0,"audio_amount") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"audio_amount","hash":{},"data":data,"loc":{"start":{"line":10,"column":136},"end":{"line":10,"column":152}}}) : helper)))
    + " avsnitt</div>\n            <p class=\"excerpt light more\">"
    + ((stack1 = (lookupProperty(helpers,"linkify")||(depth0 && lookupProperty(depth0,"linkify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"linkify","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":67}}})) != null ? stack1 : "")
    + "</p>\n            <!---<div class=\"share-icon-container\">\n                <a target=\"about:blank\" href=\"\"><img class=\"icon share\" src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-share.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":77},"end":{"line":13,"column":113}}}))
    + "\"></a>\n            </div>--->\n            <div class=\"share-icon-container\" id=\"facebook\">\n                <a target=\"about:blank\" href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"facebook_share_url") || (depth0 != null ? lookupProperty(depth0,"facebook_share_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"facebook_share_url","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":68}}}) : helper)))
    + "\"><img class=\"icon facebook\" src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-facebook.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":16,"column":102},"end":{"line":16,"column":141}}}))
    + "\"></a>\n            </div>\n            <div class=\"share-icon-container\" id=\"twitter\">\n                <a target=\"about:blank\" href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"twitter_share_url") || (depth0 != null ? lookupProperty(depth0,"twitter_share_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"twitter_share_url","hash":{},"data":data,"loc":{"start":{"line":19,"column":46},"end":{"line":19,"column":67}}}) : helper)))
    + "\"><img class=\"icon twitter\" src=\""
    + alias3((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-twitter.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":19,"column":100},"end":{"line":19,"column":138}}}))
    + "\"></a>\n            </div>\n            <br class=\"clearfix\" />\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"categories") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":80,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div><!--row-->\n</div><!--catchup-single-container-inner-->\n";
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["catchup_single"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"date-time\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":135}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":138}}})) != null ? stack1 : "")
    + "\n                    "
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":60}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":18,"column":103},"end":{"line":18,"column":142}}}))
    + "\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Säsong "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"season") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Avsnitt "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"podcast_metadata") : depth0)) != null ? lookupProperty(stack1,"episode") : stack1), depth0))
    + " <span class=\"tag-separator\">&#9679</span> ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"date-time\">"
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishdate") : depth0),"DD MMM YYYY",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":21,"column":39},"end":{"line":21,"column":79}}}))
    + " <span class=\"tag-separator\">&#9679</span> "
    + alias3((lookupProperty(helpers,"toDurationString")||(depth0 && lookupProperty(depth0,"toDurationString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toDurationString","hash":{},"data":data,"loc":{"start":{"line":21,"column":122},"end":{"line":21,"column":161}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"catchup-single-container-inner\">\n    <div class=\"row\">\n        <div class=\"small-12 medium-4 large-4 columns\">\n            <div class=\"thumbnail\">\n                <div class=\"play-button large\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":75}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":131}}}) : helper)))
    + "\" data-artist=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":5,"column":146},"end":{"line":5,"column":163}}}) : helper)))
    + "\" data-image=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":5,"column":177},"end":{"line":5,"column":186}}}) : helper)))
    + "\" data-catchup=\"true\" style=\"background-color: #"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? lookupProperty(stack1,"app_playerbar_color") : stack1), depth0))
    + ";\">\n                    <div class=\"play-icon\"></div>\n                </div>\n                <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),350,350,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":70}}}))
    + "\" width=\"350\" height=\"350\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":8,"column":102},"end":{"line":8,"column":119}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":122},"end":{"line":8,"column":131}}}) : helper)))
    + "\"/>\n            </div>\n        </div>\n        <div class=\"small-12 medium-8 large-8 columns\">\n            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":12,"column":21},"end":{"line":12,"column":36}}}) : helper)))
    + "\"><h5 class=\"light\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"program_title") || (depth0 != null ? lookupProperty(depth0,"program_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_title","hash":{},"data":data,"loc":{"start":{"line":12,"column":75},"end":{"line":12,"column":92}}}) : helper)))
    + "</h5></a>\n            <h1 class=\"light\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":39}}}) : helper)))
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_podcast") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "            <p class=\"excerpt light more\">"
    + ((stack1 = (lookupProperty(helpers,"linkify")||(depth0 && lookupProperty(depth0,"linkify"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"linkify","hash":{},"data":data,"loc":{"start":{"line":23,"column":42},"end":{"line":23,"column":67}}})) != null ? stack1 : "")
    + "</p>\n            <!---<div class=\"share-icon-container\">\n                <a target=\"about:blank\" href=\"\"><img class=\"icon share\" src=\""
    + alias4((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-share.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":77},"end":{"line":25,"column":113}}}))
    + "\"></a>\n            </div>--->\n            <div class=\"share-icon-container\" id=\"facebook\">\n                <a target=\"about:blank\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"facebook_share_url") || (depth0 != null ? lookupProperty(depth0,"facebook_share_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"facebook_share_url","hash":{},"data":data,"loc":{"start":{"line":28,"column":46},"end":{"line":28,"column":68}}}) : helper)))
    + "\"><img class=\"icon facebook\" src=\""
    + alias4((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-facebook.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":28,"column":102},"end":{"line":28,"column":141}}}))
    + "\"></a>\n            </div>\n            <div class=\"share-icon-container\" id=\"twitter\">\n                <a target=\"about:blank\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"twitter_share_url") || (depth0 != null ? lookupProperty(depth0,"twitter_share_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"twitter_share_url","hash":{},"data":data,"loc":{"start":{"line":31,"column":46},"end":{"line":31,"column":67}}}) : helper)))
    + "\"><img class=\"icon twitter\" src=\""
    + alias4((lookupProperty(helpers,"themeUrl")||(depth0 && lookupProperty(depth0,"themeUrl"))||alias2).call(alias1,"/images/ilr-twitter.svg",{"name":"themeUrl","hash":{},"data":data,"loc":{"start":{"line":31,"column":100},"end":{"line":31,"column":138}}}))
    + "\"></a>\n            </div>\n        </div>\n    </div><!--row-->\n</div><!--catchup-single-container-inner-->\n";
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["channel_big"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<li channel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":23}}}) : helper)))
    + "\">\n		<a class=\"fm-channel-link\" href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":44}}}) : helper)))
    + "/\">\n		<div class=\"fm-channel-image\" style=\"background-image: url('"
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(lookupProperty(helpers,"getLandscapeImage")||(depth0 && lookupProperty(depth0,"getLandscapeImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_images") : stack1),{"name":"getLandscapeImage","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":125}}}),340,240,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":135}}}))
    + "'), linear-gradient(transparent 52%, rgba(0, 0, 0, 0.84)); background-color: "
    + alias4((lookupProperty(helpers,"toRGBA")||(depth0 && lookupProperty(depth0,"toRGBA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_top") : depth0),{"name":"toRGBA","hash":{},"data":data,"loc":{"start":{"line":4,"column":212},"end":{"line":4,"column":239}}}))
    + ";\">\n			<div class=\"overlay\" style=\"background-image: linear-gradient("
    + alias4((lookupProperty(helpers,"toRGBA")||(depth0 && lookupProperty(depth0,"toRGBA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_top") : depth0),{"name":"toRGBA","hash":{},"data":data,"loc":{"start":{"line":5,"column":65},"end":{"line":5,"column":92}}}))
    + ", "
    + alias4((lookupProperty(helpers,"toRGBA")||(depth0 && lookupProperty(depth0,"toRGBA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_bottom") : depth0),{"name":"toRGBA","hash":{},"data":data,"loc":{"start":{"line":5,"column":94},"end":{"line":5,"column":124}}}))
    + ");\">\n			</div>\n			<img class=\"logo\" loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"logo") : depth0),144,144,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":69}}}))
    + "\" width=\"144\" height=\"144\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":136},"end":{"line":7,"column":144}}}) : helper)))
    + "\"/>\n			<div class=\"fm-stations-overlay\" style=\"background-image: linear-gradient("
    + alias4((lookupProperty(helpers,"toRGBA")||(depth0 && lookupProperty(depth0,"toRGBA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_top") : depth0),{"name":"toRGBA","hash":{},"data":data,"loc":{"start":{"line":8,"column":77},"end":{"line":8,"column":104}}}))
    + ", "
    + alias4((lookupProperty(helpers,"toRGBA")||(depth0 && lookupProperty(depth0,"toRGBA"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_bottom") : depth0),{"name":"toRGBA","hash":{},"data":data,"loc":{"start":{"line":8,"column":106},"end":{"line":8,"column":136}}}))
    + ");\">\n			</div>\n		</div>\n		<div class=\"fm-stations-container\">\n			<p class=\"show-name\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + "</p>\n			<div class=\"song-container\">\n				<div class=\"albumart\">\n					<img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"cover_art") : stack1),50,50,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":15,"column":30},"end":{"line":15,"column":78}}}))
    + "\" width=\"50\" height=\"50\">\n				</div>\n				<div class=\"current-track\">\n					<p class=\"song-title\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\n					<p class=\"artist\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</p>\n				</div>\n			</div>\n		</div>\n		</a>\n	</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":25,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["channel_item"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotCatchUp") || (depth0 != null ? lookupProperty(depth0,"isNotCatchUp") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotCatchUp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":26,"column":21}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotCatchUp")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"player-container\" channel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":55}}}) : helper)))
    + "\" style=\"background-color: #"
    + alias4(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":3,"column":83},"end":{"line":3,"column":106}}}) : helper)))
    + ";\">\n            <div class=\"container-fluid no-pm\">\n                <div class=\"player-container-inner\">\n                    <a class=\"channel-list-station-logo\" href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":6,"column":72}}}) : helper)))
    + "/\">\n                        <div class=\"logo-container-player\" style=\"background-image: url("
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"logo") : depth0),200,160,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":116}}}))
    + ");\"></div>\n                    </a>\n                    <div class=\"player-controls-container\" style=\"background-color: #"
    + alias4(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":108}}}) : helper)))
    + ";\">\n                        <div class=\"play-button\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":63},"end":{"line":10,"column":69}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"livestream") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n                            <div class=\"play-icon\" style=\"background-color: #"
    + alias4(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":100}}}) : helper)))
    + ";\"></div>\n                        </div>\n                    </div>\n                    <div id=\"stations-inner\">\n                        <div class=\"cover-art-container-player\">\n                            <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"albumThumbnail")||(depth0 && lookupProperty(depth0,"albumThumbnail"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"cover_art") : stack1),66,66,{"name":"albumThumbnail","hash":{},"data":data,"loc":{"start":{"line":16,"column":53},"end":{"line":16,"column":104}}}))
    + "\" width=\"66\" height=\"66\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":16,"column":169},"end":{"line":16,"column":177}}}) : helper)))
    + "\">\n                        </div>\n                        <div class=\"current-track\">\n                          <p class=\"song-title\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\n                          <p class=\"artist\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["channel_small"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotCatchUp") || (depth0 != null ? lookupProperty(depth0,"isNotCatchUp") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotCatchUp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":18,"column":21}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotCatchUp")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li channel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":30}}}) : helper)))
    + "\">\n            <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":30}}}) : helper)))
    + "/\">\n                <div class=\"thumbnail\" style=\"background-image: linear-gradient("
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_top") : depth0),100,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":5,"column":80},"end":{"line":5,"column":120}}}))
    + ", "
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_bottom") : depth0),100,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":165}}}))
    + ");\">\n                    <div class=\"play-button medium\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":72}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"livestream") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" style=\"background-color: #"
    + alias4(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":6,"column":133},"end":{"line":6,"column":156}}}) : helper)))
    + ";\">\n                        <div class=\"play-icon\"></div>\n                    </div>\n                    <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"logo") : depth0),200,200,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":73}}}))
    + "\" width=\"200\" height=\"200\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":140},"end":{"line":9,"column":148}}}) : helper)))
    + "\"/>\n                </div>\n                <h4 class=\"h4-artist\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":46}}}) : helper)))
    + "</h4>\n                <div class=\"current-track\">\n                    <p class=\"song-title\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\n                    <p class=\"artist\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</p>\n                </div>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":19,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["channel_song_item"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), alias5=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <div class=\"more-container\">\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"dislikes") : stack1), depth0),{"name":"song.dislikes","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":30}}})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"dislikes") : stack1), depth0),{"name":"song.dislikes","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":30}}})) != null ? stack1 : "")
    + "                <a href=\"#\" class=\"song-action-dislike\" data-song-id=\""
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><img loading=\"lazy\" src=\"/wp-content/themes/ilikeradio/assets/images/thumb-down.svg\" alt=\"downvote\" /></a>\n            </div>\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"likes") : stack1), depth0),{"name":"song.likes","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":14,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"likes") : stack1), depth0),{"name":"song.likes","hash":{},"fn":container.noop,"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "                <a href=\"#\" class=\"song-action-like\" data-song-id=\""
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><img loading=\"lazy\" src=\"/wp-content/themes/ilikeradio/assets/images/thumbup.svg\" alt=\"upvote\" /></a>\n            </div>\n        </div>\n        <div class=\"track-time-indicator\">\n           <span>"
    + alias3((lookupProperty(helpers,"formatDate")||(depth0 && lookupProperty(depth0,"formatDate"))||alias5).call(alias4,(depth0 != null ? lookupProperty(depth0,"published_at") : depth0),"HH:mm",{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":22,"column":17},"end":{"line":22,"column":52}}}))
    + "</span>\n	    </div>\n        <div class=\"track-cover-art-container\">\n		    <div class=\"track-cover-art\">\n			    <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"albumThumbnail")||(depth0 && lookupProperty(depth0,"albumThumbnail"))||alias5).call(alias4,((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"cover_art") : stack1),90,90,{"name":"albumThumbnail","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":71}}}))
    + "\" width=\"90\" height=\"90\" alt=\""
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + " - "
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "\"/>\n		    </div>\n	    </div>\n	    <div class=\"track-details\">\n	        <h4 class=\"track-title\">"
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h4>\n		    <span class=\"artist-name\">"
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</span>\n	    </div>\n    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"dislike-icon disliked\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"dislike-icon\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"like-icon liked\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"like-icon\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["listen_again_clip"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a href=\""
    + alias3((lookupProperty(helpers,"catchupUrl")||(depth0 && lookupProperty(depth0,"catchupUrl"))||alias2).call(alias1,(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"program/",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"slug") : stack1),"/",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":74}}}),{"name":"catchupUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":76}}}))
    + "\">\n            <div class=\"thumbnail\">\n                <div class=\"total-minutes\">"
    + alias3((lookupProperty(helpers,"toTimeString")||(depth0 && lookupProperty(depth0,"toTimeString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toTimeString","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":78}}}))
    + "</div>\n                <div class=\"play-button small\" style=\"background-color: #"
    + alias3(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":96}}}) : helper)))
    + ";\" data-channel=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":6,"column":113},"end":{"line":6,"column":127}}}) : helper)))
    + "\" data-stream=\""
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":174},"end":{"line":6,"column":183}}}) : helper)))
    + "\" data-image=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":6,"column":197},"end":{"line":6,"column":206}}}) : helper)))
    + "\" data-artist=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":221},"end":{"line":6,"column":230}}}) : helper)))
    + "\" data-catchup=\"true\" data-content-url=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":6,"column":270},"end":{"line":6,"column":277}}}) : helper)))
    + "\" data-program-url=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":297},"end":{"line":6,"column":312}}}) : helper)))
    + "\">\n                    <div class=\"play-icon\"></div>\n                </div>\n                <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),290,290,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":70}}}))
    + "\" width=\"290\" height=\"290\" alt=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":111}}}) : helper)))
    + " - "
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n            </div>\n            <h4>"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":25}}}) : helper)))
    + "</h4>\n            <p class=\"smalltext\">"
    + alias3((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(lookupProperty(helpers,"stripTags")||(depth0 && lookupProperty(depth0,"stripTags"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"stripTags","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":67}}}),90,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":72}}}))
    + "</p>\n        </a>\n        <a href=\"program/"
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"slug") : stack1), depth0))
    + "\"><p class=\"tags\">"
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p></a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":16,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["listen_again_clip_row"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"program-item clearfix\">\n        <a href=\""
    + alias3((lookupProperty(helpers,"catchupUrl")||(depth0 && lookupProperty(depth0,"catchupUrl"))||alias2).call(alias1,(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"program/",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"slug") : stack1),"/",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":74}}}),{"name":"catchupUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":76}}}))
    + "\">\n            <div class=\"thumbnail\">\n                <div class=\"total-minutes\">"
    + alias3((lookupProperty(helpers,"toTimeString")||(depth0 && lookupProperty(depth0,"toTimeString"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1),{"name":"toTimeString","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":78}}}))
    + "</div>\n                <div class=\"play-button small\" style=\"background-color: #"
    + alias3(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":96}}}) : helper)))
    + ";\" data-channel=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":6,"column":113},"end":{"line":6,"column":127}}}) : helper)))
    + "\" data-stream=\""
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":174},"end":{"line":6,"column":183}}}) : helper)))
    + "\" data-image=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":6,"column":197},"end":{"line":6,"column":206}}}) : helper)))
    + "\" data-artist=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":221},"end":{"line":6,"column":230}}}) : helper)))
    + "\" data-catchup=\"true\" data-content-url=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":6,"column":270},"end":{"line":6,"column":277}}}) : helper)))
    + "\" data-program-url=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":297},"end":{"line":6,"column":312}}}) : helper)))
    + "\">\n                    <div class=\"play-icon\"></div>\n                </div>\n                <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),100,100,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":70}}}))
    + "\" width=\"100\" height=\"100\" alt=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":111}}}) : helper)))
    + " - "
    + alias3(((helper = (helper = lookupProperty(helpers,"category_title") || (depth0 != null ? lookupProperty(depth0,"category_title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"category_title","hash":{},"data":data,"loc":{"start":{"line":9,"column":114},"end":{"line":9,"column":132}}}) : helper)))
    + "\">\n            </div>\n            <div class=\"item-container\">\n                <h4>"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":29}}}) : helper)))
    + "</h4>\n                <p class=\"smalltext\">"
    + alias3((lookupProperty(helpers,"stripTags")||(depth0 && lookupProperty(depth0,"stripTags"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"stripTags","hash":{},"data":data,"loc":{"start":{"line":13,"column":37},"end":{"line":13,"column":62}}}))
    + "</p>\n                <p class=\"tags\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"category_title") || (depth0 != null ? lookupProperty(depth0,"category_title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"category_title","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":50}}}) : helper)))
    + "</p>\n            </div>\n		</a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":18,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["listen_again_program"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a href=\"program/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":33}}}) : helper)))
    + "\">\n            <div class=\"thumbnail\">\n                <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),290,290,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":70}}}))
    + "\" width=\"290\" height=\"290\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":102},"end":{"line":5,"column":111}}}) : helper)))
    + "\">\n            </div>\n            <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":25}}}) : helper)))
    + "</h4>\n            <p class=\"smalltext\">"
    + alias4((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(lookupProperty(helpers,"stripTags")||(depth0 && lookupProperty(depth0,"stripTags"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"stripTags","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":67}}}),186,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":73}}}))
    + "</p>\n            <p class=\"link-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"content_amount") || (depth0 != null ? lookupProperty(depth0,"content_amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content_amount","hash":{},"data":data,"loc":{"start":{"line":9,"column":33},"end":{"line":9,"column":51}}}) : helper)))
    + " KLIPP</p>\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["listen_again_program_row"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"program-group\">\n	    <h2 class=\"section-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":43}}}) : helper)))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"program-item clearfix\">\n                <div class=\"thumbnail\">\n                    <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),150,150,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":74}}}))
    + "\" width=\"150\" height=\"150\" alt=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":7,"column":106},"end":{"line":7,"column":115}}}) : helper)))
    + "\"/>\n                </div>\n                <a href=\""
    + alias3((lookupProperty(helpers,"catchupUrl")||(depth0 && lookupProperty(depth0,"catchupUrl"))||alias2).call(alias1,(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"program/",(depth0 != null ? lookupProperty(depth0,"slug") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":62}}}),{"name":"catchupUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":64}}}))
    + "\" class=\"click-item-container\">\n                	<div class=\"item-container\">\n                	    <h4>"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":34}}}) : helper)))
    + "</h4>\n                	    <p class=\"smalltext\">"
    + alias3((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(lookupProperty(helpers,"stripTags")||(depth0 && lookupProperty(depth0,"stripTags"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"stripTags","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":76}}}),235,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":82}}}))
    + "</p>\n                	    <p class=\"link-text\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"audio_amount") || (depth0 != null ? lookupProperty(depth0,"audio_amount") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"audio_amount","hash":{},"data":data,"loc":{"start":{"line":13,"column":42},"end":{"line":13,"column":58}}}) : helper)))
    + " avsnitt</p>\n                	</div>\n                </a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"group")||(depth0 && lookupProperty(depth0,"group"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"group","hash":{"by":"section"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":19,"column":10}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["podcast_small_active"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n      <a href=\"/podcasts/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":33}}}) : helper)))
    + "/\">\n        <div>\n          <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),200,200,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":35},"end":{"line":5,"column":64}}}))
    + "\" width=\"200\" height=\"200\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":96},"end":{"line":5,"column":105}}}) : helper)))
    + "\"/>\n        </div>\n        <div class=\"divider\"></div>\n        <div class=\"title medium\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":43}}}) : helper)))
    + "</div>\n        <div class=\"divider\"></div>\n        <p class=\"smalltext\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":44}}}) : helper)))
    + "</p>\n      </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["podcast_small_all"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"program-group\">\n	    <h2 class=\"section-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":43}}}) : helper)))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":19,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"program-item clearfix\">\n                <div class=\"list-image-container\">\n                    <img loading=\"lazy\" src=\""
    + alias3((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),100,100,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":74}}}))
    + "\" width=\"100\" height=\"100\" alt=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":106},"end":{"line":7,"column":115}}}) : helper)))
    + "\"/>\n                </div>\n                <a href=\"/podcasts/"
    + alias3(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":43}}}) : helper)))
    + "/\" class=\"click-item-container\">\n                	<div class=\"item-container\">\n                        <div class=\"divider\"></div>\n                	    <div class=\"title medium\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":56}}}) : helper)))
    + "</div>\n                        <div class=\"divider\"></div>\n                	    <p class=\"smalltext\">"
    + alias3((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(lookupProperty(helpers,"stripTags")||(depth0 && lookupProperty(depth0,"stripTags"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"stripTags","hash":{},"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":76}}}),235,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":82}}}))
    + "</p>\n                	    <p class=\"link-text\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"audio_amount") || (depth0 != null ? lookupProperty(depth0,"audio_amount") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"audio_amount","hash":{},"data":data,"loc":{"start":{"line":15,"column":42},"end":{"line":15,"column":58}}}) : helper)))
    + " avsnitt</p>\n                	</div>\n                </a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"group")||(depth0 && lookupProperty(depth0,"group"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"group","hash":{"by":"section"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":10}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["podcasts_latest_episode"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"more-episodes-container\">\n    <div class=\"thumbnail\">\n        <div class=\"play-button tiny\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"channel_id") || (depth0 != null ? lookupProperty(depth0,"channel_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":66}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"mediafile") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":113},"end":{"line":4,"column":122}}}) : helper)))
    + "\" data-artist=\""
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\" data-image=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":4,"column":173},"end":{"line":4,"column":182}}}) : helper)))
    + "\" data-catchup=\"true\" data-content-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":4,"column":222},"end":{"line":4,"column":229}}}) : helper)))
    + "\" data-program-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"program_url") || (depth0 != null ? lookupProperty(depth0,"program_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"program_url","hash":{},"data":data,"loc":{"start":{"line":4,"column":249},"end":{"line":4,"column":264}}}) : helper)))
    + "\" style=\"background-color: #"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? lookupProperty(stack1,"app_playerbar_color") : stack1), depth0))
    + ";\">\n            <div class=\"play-icon\"></div>\n        </div>\n        <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),200,200,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":62}}}))
    + "\" width=\"200\" height=\"200\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":94},"end":{"line":7,"column":103}}}) : helper)))
    + " - "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n    </div>\n    <a href=\"/podcasts/"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"slug") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":51}}}) : helper)))
    + "\">\n        <div class=\"pod-title-container\">\n            <h5 class=\"light\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + " - "
    + alias4((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),20,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":11,"column":55},"end":{"line":11,"column":76}}}))
    + "<br></h5>\n            <p class=\"smalltext light\">"
    + alias4((lookupProperty(helpers,"truncate")||(depth0 && lookupProperty(depth0,"truncate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),120,{"name":"truncate","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":67}}}))
    + "</p>\n            <div class=\"tags\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"categories") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</div>\n        </div>\n    </a>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["related_station_item"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"related-stations-item-wrapper large-4 medium-12 small-12\">\n    <div class=\"related-stations-item\" channel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":57}}}) : helper)))
    + "\" style=\"background-color: "
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0),80,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":2,"column":84},"end":{"line":2,"column":126}}}))
    + "; background-image: url("
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"logo") : depth0),90,90,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":2,"column":150},"end":{"line":2,"column":176}}}))
    + ")\">\n        <a href=\"#\" class=\"play-button square\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":67}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"livestream") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" style=\"background-color: "
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0),90,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":3,"column":127},"end":{"line":3,"column":169}}}))
    + ";\">\n            <div class=\"play-icon\"></div>\n        </a>\n        <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":6,"column":18},"end":{"line":6,"column":26}}}) : helper)))
    + "/\">\n            <div class=\"trackinfo\">\n                <div class=\"track\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</div>\n                <div class=\"artist\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</div>\n            </div>\n        </a>\n    </div>\n</div>";
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["temporary_channel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"small-6 medium-6 large-2 columns\" channel-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":67}}}) : helper)))
    + "\">\n        <a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":26}}}) : helper)))
    + "/\">\n            <div class=\"thumbnail\" style=\"background-image: linear-gradient("
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_top") : depth0),100,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":116}}}))
    + ", "
    + alias4((lookupProperty(helpers,"toRGBAWithAlpha")||(depth0 && lookupProperty(depth0,"toRGBAWithAlpha"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"app_gradient_bottom") : depth0),100,{"name":"toRGBAWithAlpha","hash":{},"data":data,"loc":{"start":{"line":4,"column":118},"end":{"line":4,"column":161}}}))
    + ");\">\n                <div class=\"play-button medium\" data-channel=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":68}}}) : helper)))
    + "\" data-stream=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"livestream") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" style=\"background-color: #"
    + alias4(((helper = (helper = lookupProperty(helpers,"app_playerbar_color") || (depth0 != null ? lookupProperty(depth0,"app_playerbar_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_playerbar_color","hash":{},"data":data,"loc":{"start":{"line":5,"column":129},"end":{"line":5,"column":152}}}) : helper)))
    + ";\">\n                    <div class=\"play-icon\"></div>\n                </div>\n                <img loading=\"lazy\" src=\""
    + alias4((lookupProperty(helpers,"cdnImageUrl")||(depth0 && lookupProperty(depth0,"cdnImageUrl"))||alias2).call(alias1,(lookupProperty(helpers,"getLandscapeImage")||(depth0 && lookupProperty(depth0,"getLandscapeImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_images") : stack1),{"name":"getLandscapeImage","hash":{},"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":104}}}),340,240,{"name":"cdnImageUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":114}}}))
    + "\" width=\"340\" height=\"240\" alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":181},"end":{"line":8,"column":189}}}) : helper)))
    + "\"/>\n            </div>\n            <h4>"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"currentprogram") : depth0)) != null ? lookupProperty(stack1,"program_title") : stack1), depth0))
    + "</h4>\n            <div class=\"current-track\">\n              <p class=\"song-title\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\n              <p class=\"artist\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"currentsong") : depth0)) != null ? lookupProperty(stack1,"song") : stack1)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</p>\n            </div>\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"useData":true});
var Handlebars = require ('handlebars');
this["ilr"]["templates"]["wishlist_song"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"navigation-heading-container\">\n        <div class=\"request-message-container\">\n            <div class=\"song-request-name\">3min ago Önskat av Jesper</div>\n        </div>\n    </div>\n    <li>\n        <div class=\"more-container\">\n        		<div class=\"share-icon shared\" data-song-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n        		<a href=\"#\" class=\"song-action-share\" data-song-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><img loading=\"lazy\" src=\"/wp-content/themes/ilikeradio/assets/images/share.svg\" alt=\"share\" /></a>\n        		</div>\n"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"dislikes") : stack1), depth0),{"name":"song.dislikes","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":14,"column":30}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"dislikes") : stack1), depth0),{"name":"song.dislikes","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":30}}})) != null ? stack1 : "")
    + "                <a href=\"#\" class=\"song-action-dislike\" data-song-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><img loading=\"lazy\" src=\"/wp-content/themes/ilikeradio/assets/images/thumb-down.svg\" alt=\"downvote\" /></a>\n                </div>\n"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"likes") : stack1), depth0),{"name":"song.likes","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"likes") : stack1), depth0),{"name":"song.likes","hash":{},"fn":container.noop,"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "            <a href=\"#\" class=\"song-action-like\" data-song-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><img loading=\"lazy\" src=\"/wp-content/themes/ilikeradio/assets/images/thumbup.svg\" alt=\"upvote\" /></a>\n        </div>\n        </div>\n        <div class=\"track-cover-art-container\">\n        	<a href=\"#\">\n        	    <div class=\"track-cover-art\">\n        	        <img loading=\"lazy\" src=\""
    + alias2((lookupProperty(helpers,"albumThumbnail")||(depth0 && lookupProperty(depth0,"albumThumbnail"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"cover_art") : stack1),90,90,{"name":"albumThumbnail","hash":{},"data":data,"loc":{"start":{"line":32,"column":42},"end":{"line":32,"column":81}}}))
    + "\" width=\"90\" height=\"90\" />\n                </div>\n            </a>\n        </div>\n        <div class=\"track-details\">\n            <h4 class=\"track-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h4>\n        	<span class=\"artist-name\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"artist_name") : stack1), depth0))
    + "</span>\n        </div>\n    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"dislike-icon disliked\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"dislike-icon\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"like-icon liked\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"like-icon\" data-song-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"song") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":9}}})) != null ? stack1 : "");
},"useData":true});